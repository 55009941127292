<template>
  <v-checkbox
    v-if="visible"
    v-model="value"
    :label="label"
    :error-messages="meta.dirty || meta.touched ? errors : []"
  >
    <template #label>
      <span v-html="label" />
      <mtf-field-icon
        :meta="meta"
        :has-errors="hasErrors"
      />
    </template>
  </v-checkbox>
</template>

<script>
import { defineComponent } from 'vue';
import { useMatterificField } from '#imports';

import MtfFieldIcon from '../FieldIcon.vue';

export default defineComponent({
  name: 'MtfFieldCheckbox',
  components: { MtfFieldIcon },
  inheritAttrs: true,
  customOptions: {},
  // ----------------
  props: {
    schema: { type: Object, required: true },
    name: { type: String, required: true },
    label: { type: String, required: true },
    visible: { type: Boolean, default: true }
  },
  //emits: ['update:modelValue', 'input',],
  // ----------------

  setup(props, context) {
    const { meta, value, errors, handleBlur, validate, hasErrors } = useMatterificField(
      props.name,
      props.schema,
      context
    );
    return {
      meta,
      value,
      errors,
      handleBlur,
      validate,
      hasErrors
    };
  }
});
</script>
